
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Secular+One&display=swap');


::-webkit-scrollbar-thumb {
    background: #FF0000;
}
*{
    scroll-behavior: smooth;
}

body{

}

#containerElement {
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
#containerElement::-webkit-scrollbar { 
    display: none; 
}

.allService .active{
    position: relative;
    background-color: rgb(241 245 249);
    color: rgb(255, 132, 17);
}

.allService .active::after{
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background-color: rgb(255, 132, 17);
}


/* -----------------for quill---------------- */

ul{
    list-style-type: disc;
  }
  
  .ql-indent-1{
    margin-left: 10px;
  }
  
  .ql-indent-2{
    margin-left: 15px;
  }
  
  .ql-indent-3{
    margin-left: 20px;
  }
  
  .ql-indent-4{
    margin-left: 25px;
  }
  
  
  
  .ql-size-10px{
    font-size: 10px;
  }
  .ql-size-12px{
    font-size: 12px;
  }
  .ql-size-14px{
    font-size: 14px;
  }
  .ql-size-16px{
    font-size: 16px;
  }
  .ql-size-18px{
    font-size: 18px;
  }
  .ql-size-20px{
    font-size: 20px;
  }
  
  
  .ql-size-12px{
    font-size: 22px;
  }
  .ql-size-24px{
    font-size: 24px;
  }
  .ql-size-26px{
    font-size: 26px;
  }
  .ql-size-28px{
    font-size: 28px;
  }
  
  .ql-size-30px{
    font-size: 30px;
  }
  .ql-size-32px{
    font-size: 32px;
  }
  .ql-size-34px{
    font-size: 34px;
  }
  .ql-size-36px{
    font-size: 36px;
  }
  .ql-size-38px{
    font-size: 38px;
  }
  
  .ql-size-40px{
    font-size: 40px;
  }
  .ql-size-42px{
    font-size: 42px;
  }
  .ql-size-44px{
    font-size: 44px;
  }
  .ql-size-46px{
    font-size: 46px;
  }
  .ql-size-48px{
    font-size: 48px;
  }
  .ql-size-50px{
    font-size: 50px;
  }

  /* -----------------for quill---------------- */


  /* ----------------------------- */

  .shadow1{
    border-radius: 6px;
    background: linear-gradient(145deg, #fa5e0d, #d34f0b);
    box-shadow:  3px 3px 6px #c74b0a,
                 -3px -3px 6px #ff650e;
  }

  .shadow2{
    border-radius: 6px;
    background: linear-gradient(145deg, #d34f0b, #fa5e0d);
    box-shadow:  3px 3px 6px #c74b0a,
                 -3px -3px 6px #ff650e;
  }
  

.glow{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  background: rgb(255, 88, 10);
  box-shadow:  -2px 2px 14px #e95403,
  2px -2px 14px #e65400;

  transition: all 0.2s ease-in-out;
}

.glow:hover{
  color: rgba(255, 255, 255, 1);
  box-shadow:  -2px 2px 30px #e95403,
  2px -2px 30px #e65400;
}